.widget-page {
}

// schema tab in widget form
.schema-field-container {
    .add-schema {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;

        button {
            font-size: 1.4rem;
            padding: 10px 18px !important;
        }

        > span > span {
            color: white;
            cursor: pointer;
        }
    }

    .schema-field-items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        gap: 20px;

        // view mode
        .schema-field-item__view {
            border-radius: 7px;
            width: 100%;
            position: relative;
            border: 1px dashed #fff2;
            background-color: #2c2d45;
            padding: 10px 20px;

            &__title{
                display: grid;
                grid-template-columns: 1fr 4fr;
                align-items: baseline;
                column-gap: 15px;
                margin : 10px 0;

                h3, h5{
                    margin: 0;
                }

                h5{
                    opacity: .5;
                    justify-self: end;
                }

            }

            &__allowBoostingIcon{
                display: grid;
                place-items: center;
                width: 25px;
                height: 25px;
                border-radius: 100%;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -50%);
                z-index: 2;
                background-color: #c9cae6;
            }

            &__actions {
                position: absolute;
                top: 0;
                right: 0;
                padding: 2px 5px;
                height: 30px;
                overflow: hidden;
                background-color: #c9cae6;
                color: #4b4c6e;
                border-radius: 0 8px 0 8px;
                opacity: 0;
                transition: 0.3s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                i {
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        color: #3e8eb3;
                    }
                }
            }

            &:hover .schema-field-item__view__actions {
                opacity: 1;
            }

            .schema-logic-select {
                width: 100%;
                &:not(.cx-select-open) .cx-select__trigger {
                    background-color: transparent;
                    border-bottom: 1px solid #4b4c6e;
                    border-radius: 0;
                }
            }

            .schema-category-input input {
                padding: 8px 15px;
                border-radius: 0px;
                border: none;
                border-bottom: 1px solid #4b4c6e;
                &::selection {
                    background-color: #4b4c6e;
                }
            }
        }

        // edit mode
        .schema-field-item__edit {
            padding: 12px 20px 20px 20px;
            border-radius: 8px;
            width: 100%;
            background-color: #28283d;
            position: relative;

            &__title{
                display: grid;
                grid-template-columns: 1fr 4fr;
                align-items: baseline;
                column-gap: 15px;
                margin : 10px 0;



                h5{
                    margin : 0;
                    opacity: .5;
                    justify-self: end;
                }

            }

            &__actions {
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 30px;
                overflow: hidden;
                background-color: #c9cae6;
                color: #4b4c6e;
                border-radius: 0 8px 0 8px;
                transition: 0.3s ease-in-out;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                i {
                    cursor: pointer;
                    transition: 0.3s;

                    &:hover {
                        color: #55c0f0;
                    }
                }
            }


            .schema-logic-select {
                width: 100%;
                &:not(.cx-select-open) .cx-select__trigger {
                    background-color: transparent;
                    border-bottom: 1px solid #4b4c6e;
                    border-radius: 0;
                }
            }

            .schema-category-input input {
                padding: 8px 15px;
                border-radius: 0px;
                border: none;
                border-bottom: 1px solid #4b4c6e;
                &::selection {
                    background-color: #4b4c6e;
                }
            }
        }
    }
}
