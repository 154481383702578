.section {
  background-color: #32334d;
  overflow-y: auto;
  direction: ltr;
  scrollbar-color: #1e1e2f;
  scrollbar-width: thin;
}

.section::-webkit-scrollbar {
  width: 20px;
  background-color: #32334d;
}

.section::-webkit-scrollbar-track {
  background-color: #32334d;
  border-radius: 100px;
}

.section::-webkit-scrollbar-thumb {
  border-radius: 30px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: rgba(78, 78, 78, 0.84);
}
