// Nucleo icons
@import "../bootstrap/_daterangepicker";
@import url("https://fonts.googleapis.com/css?family=Tajawal&display=swap");

.section-nucleo-icons .icons-container {
  position: relative;
  max-width: 100%;
  height: 360px;
  margin: 0 auto;
  z-index: 1;
}



.section-nucleo-icons {
  --icon-size: 5rem;
  --icon-sm-size: 3.75rem;
  --gutter: 7rem;

  .icons-container {
    i {
      position: absolute;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $white;
      z-index: 1;
      transform: translate(-50%, -50%);
      @include box-shadow($box-shadow);
      transition: all 0.2s cubic-bezier(0.25, 0.65, 0.9, 0.75);

      &.icon {
        width: var(--icon-size);
        height: var(--icon-size);
        font-size: 1.7em;
      }

      &.icon-sm {
        width: var(--icon-sm-size);
        height: var(--icon-sm-size);
        font-size: 1.5em;
      }

      &:nth-child(1) {
        font-size: 42px;
        color: theme-color("warning");
        z-index: 2;
      }
    }

    &:not(.on-screen) {
      i {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;

        &:not(:nth-child(1)) {
          opacity: 0;
        }
      }
    }

    &.on-screen {
      i {
        opacity: 1;

        &:nth-child(1) {
          left: 50%;
          top: 50%;
          font-size: 42px;
          color: theme-color("warning");
        }

        &:nth-child(2) {
          left: calc(50% + (var(--gutter) * 1.7));
          top: 50%;
        }

        &:nth-child(3) {
          left: calc(50% + var(--gutter));
          top: calc(50% + var(--gutter));
        }

        &:nth-child(4) {
          left: calc(50% + var(--gutter));
          top: calc(50% - var(--gutter));
        }

        &:nth-child(5) {
          left: calc(50% + (var(--gutter) * 4));
          top: 50%;
        }

        &:nth-child(6) {
          left: calc(50% + (var(--gutter) * 2.7));
          top: calc(50% + (var(--gutter) * 1.5));
        }

        &:nth-child(7) {
          left: calc(50% + (var(--gutter) * 2.7));
          top: calc(50% - (var(--gutter) * 1.5));
        }

        &:nth-child(8) {
          left: calc(50% - (var(--gutter) * 1.7));
          top: 50%;
        }

        &:nth-child(9) {
          left: calc(50% - var(--gutter));
          top: calc(50% + var(--gutter));
        }

        &:nth-child(10) {
          left: calc(50% - var(--gutter));
          top: calc(50% - var(--gutter));
        }

        &:nth-child(11) {
          left: calc(50% - (var(--gutter) * 4));
          top: 50%;
        }

        &:nth-child(12) {
          left: calc(50% - (var(--gutter) * 2.7));
          top: calc(50% + (var(--gutter) * 1.5));
        }

        &:nth-child(13) {
          left: calc(50% - (var(--gutter) * 2.7));
          top: calc(50% - (var(--gutter) * 1.5));
        }
      }
    }
  }
}

// Blurable sections

.blur--hover {
  position: relative;

  .blur-item {
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    will-change: transform;
    filter: blur(0);
    opacity: 1;
  }

  .blur-hidden {
    position: absolute;
    top: calc(50% + 7px);
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: $transition-base;
    z-index: 100;
  }
  &:hover {
    .blur-item {
      opacity: 0.8;
      filter: blur(10px);
      transform: scale(0.95);
      z-index: 1;
    }
    .blur-hidden {
      opacity: 1;
      top: 50%;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-auto {
  cursor: auto;
}
.add-more-creatives i {
  font-size: 100%;
}
.icon-design-button {
  color: $primary;
  font-size: 120%;
  > * {
    margin-left: 6px;
  }
}
.cognativex-card-box {
  img {
    max-width: 150px;
  }
}
.cognativex-loader span.loader {
  background-image: url(~assets/img/loader.gif);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 50px;
}
.line-chart-100-p {
  .highcharts-container,
  [data-highcharts-chart] {
    width: 100% !important;
  }
}

.cx-table-ico-color {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}

.cx-pixel-list-popup {
  .cx-card-box-container .cx-card-box-body,
  .ReactTable {
    position: unset;
    .rt-noData {
      width: 27%;
      height: 45px;
    }
  }
}

.cx-publisher-page-table {
  position: unset;
  .cx-publisher-list-table {
    .ReactTable {
      position: unset !important;
    }
  }
}

$widthScriptPopup: 670px;
$heightScriptPopup: 284px;
.cx-js-script {
  position: absolute;
  left: calc((100vw - #{$widthScriptPopup}) / 2);
  top: calc((100vh - #{$heightScriptPopup}) / 2);
  width: $widthScriptPopup;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  .cx-js-script-title {
    color: $card-color !important;
  }
  .cx-js-script-close {
    font-size: 15px;
    color: $card-color !important;
  }
  .cx-js-script-copy,
  .cx-js-script-copy * {
    font-size: 13px;
    display: inline-block;
    padding-top: 10px;
    color: $dblue-color !important;
  }
}

.cx-pixels-form-container {
  background: #fff;
  position: absolute;
  z-index: 2;
  min-width: 279px;
  border-radius: 5px;

  .cx-pixels-form-title {
    color: $card-color;
    font-weight: 600;
    font-size: 15px;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 15px;
  }
  .cx-pixels-form-body {
    padding: 10px 15px;
    .cx-input-label {
      min-width: 76px;
      font-size: 13px;
    }
    .cx-input-icon-input {
      font-size: 12px;
      color: $dblack-color !important;
    }
  }
  &:before {
    position: absolute;
    left: -10px;
    top: 50px;
    content: " ";
    background-color: #fff;
    height: 20px;
    width: 20px;
    z-index: -1;
    transform: rotate(45deg);
  }
  &.cx-pixels-create {
    margin-top: -71px;
    margin-left: 89px;
  }
  &.cx-pixels-update {
    margin-top: 14px;
    margin-left: 135px;
  }
}

.cx-table-ico-state {
  width: calc(100% - 20px);
}

.cx-publish-errors {
  i {
    font-size: 18px;
  }
  h5 {
    color: $dblack-color;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .cx-publish-errors-list {
    color: $grey-purple;
  }
}

.cx-login-form {
  .cx-input-icon-container {
    width: 100%;
  }
  .cx-login-input {
    margin-bottom: 18px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: #ffffff !important;
      color: $card-color !important;
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
      -webkit-text-fill-color: $card-color !important;
    }

    input {
      color: $card-color !important;
    }
  }
}

.cx-login-logo h3 {
  color: $card-color !important;
  font-weight: 600;
}

.cx-search-result {
  color: $lgreen-color !important;
}

.cx-publish-content-table {
  min-height: 20vh;
  max-height: 50vh;
  overflow-y: auto;
}

.cx-tab-subtitle {
  font-size: 76%;
}

.cx-tab-remove-selected {
  font-size: 15px;
  position: absolute;
  right: 18px;
  top: calc(50% - 13px);
  padding: 2px 10px;
  color: $lgrey-color;
  box-shadow: 1px 1px 5px 0px rgba($grey-bar, 0.6);
  background: $dblack-color;
  border-radius: 26px;
  cursor: pointer;
  font-weight: 300;
}

.cx-card-title {
  position: relative;
  .cx-tooltip {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.cx-w-100 {
  width: 100%;
}

.cx-notify-component {
  border-radius: 0 !important;
  border-top: 5px solid $dblue-color;
  background-color: $card-color !important;
  h2 {
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #fff !important;
  }
  .btn-primary {
    &.cx-notify-confirm,
    &.cx-notify-confirm:hover {
      background: $dblue-color !important;
    }
  }

  .btn-link {
    &.cx-notify-cancel,
    &.cx-notify-cancel:hover {
      background: $grey-bar !important;
    }
  }

  .btn-primary.cx-notify-confirm,
  .cx-notify-cancel.btn-link {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    padding: 8px 10px !important;
    color: rgba(#fff, 1) !important;
    &:hover {
      color: rgba(#fff, 0.5) !important;
    }
    box-shadow: unset !important;
  }
}

.cx-mini-boxes {
  // border: 1px solid rgb(228, 76, 196);
  position: relative;
  overflow: hidden;
  margin: 4px 1px;
  cursor: pointer;
  color: #ffffff;
  h5 {
    color: #fff;
  }
  p {
    color: $blue-color !important;
    font-size: 18px;
    font-weight: 600;
  }
}
.font-weight-500 {
  font-weight: 500;
}

.btn.selected-date-range-btn {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  background-image: unset;
  background-color: $grey-bar;
  background-image: unset !important;
  .selected-date-range-btn-icon {
    align-items: center;
  }
  &:hover {
    color: rgba(#fff, 0.5) !important;
    background: $grey-bar !important;
  }
}
.card .btn.selected-date-range-btn {
  background-color: lighten($card-black-background, 30%);
  background-image: unset !important;
  &:hover {
    background-color: lighten($card-black-background, 30%) !important;
  }
}
#cx-pop-filters
  .react-select
  .react-select__control
  .react-select__indicators
  .react-select__clear-indicator:before {
  color: $blue-color !important;
}
.cx-filter-bottom-alignment {
  margin-bottom: 30px;
}
.cx-filter-wrapper {
  min-height: 40px;
  //margin-top: 4px;
  padding: 5px 10px;
  align-items: center;
  background: $card-black-background;
  border-radius: 8px;
  .filter-add-btn {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 22px;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-flex;
    cursor: pointer;
    outline: none;
  }
  .filter-selected {
    margin: 3px 0px 3px 10px;
  }
  .filter-pill {
    background: #fff;
    color: $card-black-background;
    border-radius: 8px;
    padding: 0 5px;
    .filter-type {
      font-size: 80%;
      margin-right: 2px;
    }
    .filter-delete {
      i {
        font-weight: 600;
        font-size: 12px;
        margin: 0 2px;
      }
    }
  }
}

.cx-throbber {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e1e2d;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 111px;
  }
}

.cx-blue-color {
  color: $lblue-color;
}

.cx-bluegray-color {
  color: $blue-gray-color;
}

.cx-tooltip-content {
  .popover-header,
  .popover-body {
    padding: 8px;
  }
  .popover-body {
    color: #333;
    font-weight: 500;
  }
  .cx-recommendation-default-freshness {
    display: flex;
    align-items: center;
    .cx-input-icon-container {
      width: 60px;
      input.cx-input-icon-input,
      textarea.cx-input-icon-input {
        color: $dblack-color;
        height: 34px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      
    }
    button {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.cx-tooltip {
  display: unset;
  .cx-info {
    font-size: 20px;
  }
}

.cx-white-color {
  color: rgba(255, 255, 255, 0.8);
}

.cx-insights-summary {
  .cx-insights-value {
    color: $blue-color;
    font-size: 28px;
    font-weight: 600;
  }
}

.cognativex-loader {
  position: relative;
}

.performance-table {
  .pvtFilterBox * {
    color: #333;
  }
  .pvtAxisContainer,
  .pvtVals {
    border: 2px solid #000;
    background: #3e3f4b;
    box-shadow: 1px 1px 20px #000;
  }
  .pvtRowOrder,
  .pvtColOrder {
    color: #fff;
  }
}
textarea.cx-input-icon-input{
  background-color: #32334d;
  color: #f3f3f4;
}
.cognativex-schedule {
  .white {
    color: white;
  }
  .cognativex-schedule-mobile {
    .container-hour {
      .third-date {
        margin-top: 10px;
      }
    }
    > .col-3 {
      padding-top: 16px;
    }
    .hours-of-day {
      margin-top: 16px;
      margin-bottom: 56px;
    }

    .days-titles {
    }

    .day-hour-choice {
      font-size: 0;
      background-color: transparent;
      transition: background-color 0.3s ease;
      width: 20px;
      height: 15px;
      display: inline-block;
      margin-right: 6px;
      border: 1px solid gray;
      position: relative;
      bottom: 0;
      line-height: 14px;
    }

    .day-hour-choice.third-date {
      margin-left: 10px;
    }

    .container-hour > div > span:last-child {
      &:before {
        content: "<";
        color: gray;
        position: absolute;
        display: block;
        font-size: 14px;
        top: 0px;
        left: 5px;
      }
    }

    .day-hour-choice-allday {
      position: relative !important;
      &:before {
        content: ">";
        position: absolute;
        display: block;
        font-size: 14px;
        left: 5px;
        top: 0px;
        z-index: 0;
        color: gray;
        transform: rotate(90deg);
      }
    }
  }
  .cognativex-schedule-desktop {
    display: none;
    .day-hour-choice {
      font-size: 0;
      background-color: transparent;
      transition: background-color 0.3s ease;
      width: 12.6px;
      height: 15px;
      display: inline-block;
      margin-right: 4px;
      border: 1px solid gray;
      position: relative;
      bottom: 8px;
    }
    span.day-hour-titles {
      width: 49px;
      display: inline-block;
      margin-right: 0px;
      margin-left: 10px;
    }

    span.day-hour-choice.third-date {
      margin-left: 10px;
    }
    .container-day-Every .day-hour-choice {
      &:before {
        content: ">";
        color: gray;
        position: absolute;
        display: block;
        font-size: 14px;
        top: -3px;
        left: 3px;
        transform: rotate(-90deg);
      }
    }
    .day-hour-choice-allday {
      position: absolute !important;
      right: -9px;
      z-index: 0;
      &:before {
        content: ">";
        position: absolute;
        display: block;
        font-size: 14px;
        left: 3px;
        top: -2.5px;
        z-index: 0;
        color: gray;
      }
    }
  }
  .day-hour-choice {
    cursor: pointer;
    &.active {
      background-color: #fff;
    }
  }
  .day-hour-choice.hovered:not(.active) {
    background: gray !important;
  }
}

.cognativex-side-bar1-card a,
.cognativex-side-bar1-card a:hover,
.cognativex-side-bar1-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-side-bar1-styling {
  display: block;
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 1px 5px #d7d7d7;
}
.cognativex-side-bar1-container {
  display: block;
  padding: 2% 5%;
}
.cognativex-side-bar1-block-title {
  border-bottom: 1px solid #eee;
  box-shadow: 0 0 1px #d7d7d7;
}
.cognativex-side-bar1-block-title h2 {
  margin: 0;
}
.cognativex-side-bar1-title {
  margin: 3% 0 5%;
}
.cognativex-side-bar1-title * {
  margin: 0;
}
.cognativex-side-bar1-title p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  padding-right: 5px;
}
.cognativex-side-bar1-title p {
  color: #757270;
  padding-top: 1%;
}
.cognativex-side-bar1-image {
  border-radius: 0;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
.cognativex-widget-recommendation-side-bar {
  width: 100%;
}
@media (min-width: 1025px) {
  .cognativex-side-bar1-main-box {
    padding-top: 8%;
    padding-bottom: 8%;
  }
}
@media (max-width: 1024px) {
  .cognativex-side-bar1-image {
    width: 100%;
  }
  .cognativex-side-bar1-main-box {
    padding-top: 7%;
    padding-bottom: 7%;
  }
}
@media (max-width: 768px) {
  .cognativex-widget-recommendation-side-bar {
    width: 100%;
  }
}

.cognativex-banner1-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-banner1-card {
  padding: 1%;
  width: 100%;
}
.cognativex-banner1-styling {
  display: block;
  width: 100%;
}
.cognativex-banner1-article-container {
  position: relative;
}
.cognativex-banner1-title {
  position: absolute;
  bottom: 0;
  width: 96%;
  text-align: center;
  margin: 0 2%;
}
.cognativex-banner1-main-box {
  display: flex;
  flex-wrap: wrap;
}
.cognativex-banner1-main-box > * {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cognativex-banner1-contained2 {
  display: flex;
  flex-wrap: wrap;
}
.cognativex-banner1-image-container .cognativex-readmore2-close-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 20px;
}
.cognativex-readmore2-close-btn img {
  width: 100%;
}
.cognativex-readmore2-card a,
.cognativex-readmore2-card a:hover,
.cognativex-readmore2-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-readmore2-article-src-container {
  width: 91%;
  height: 91%;
  position: fixed;
  top: 4%;
  background: #fff;
  left: 4%;
  border: 1px solid #eee;
  margin: 0 auto;
  overflow: auto;
  padding: 20px;
  z-index: 1;
  box-shadow: 0 1px 5px #ccc;
}
.cognativex-readmore2-card {
  padding: 1%;
  width: 33.33%;
}
.cognativex-readmore2-styling {
  display: block;
  width: 100%;
}
.cognativex-readmore2-date-text {
  color: #5b9caf;
}
.cognativex-readmore2-card .cognativex-readmore2-title h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cognativex-readmore2-link {
  display: inline-block;
}
.cognativex-readmore2-link h5 {
  display: inline-block;
  margin: 0;
  border: 1px solid #eee;
  padding: 7px 16px;
  background-color: #fff;
  transition: background-color 0.5s ease;
}
.cognativex-readmore2-link:hover h5 {
  background-color: #f2f2f2;
}
.cognativex-readmore2-main-box {
  display: flex;
  flex-wrap: wrap;
}
.cognativex-readmore2-title {
  border: 1px solid #eee;
  padding: 3%;
  margin-top: 2px;
}
.cognativex-readmore2-contained1 {
  width: 60%;
}
.cognativex-readmore2-contained2 {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
}
.cognativex-readmore2-image-container {
  display: flex;
  align-items: center;
}
.cognativex-readmore2-block-title h3 {
  margin: 1% 1% 0.5%;
}
.cognativex-readmore2-image {
  width: 100%;
}
.show-more {
  cursor: pointer;
}
.show-more span {
  transform: rotate(-90deg);
  display: block;
  width: 100%;
  text-align: center;
}
.cognativex-hide {
  display: none !important;
}
@media (max-width: 768px) {
  .cognativex-widget-recommendation-readmore {
    width: 100%;
  }
  .cognativex-readmore2-main-box > * {
    width: 100%;
  }
}
.cognativex-readmore2-main-box img {
  width: 100%;
  filter: brightness(62%);
  -webkit-filter: brightness(62%);
  -webkit-transition: 0.5s -webkit-filter linear;
  -moz-transition: 0.5s -moz-filter linear;
  -moz-transition: 0.5s filter linear;
  -ms-transition: 0.5s -ms-filter linear;
  -o-transition: 0.5s -o-filter linear;
  transition: 0.5s filter linear, 1s -webkit-filter linear;
}
.cognativex-banner1-link:hover img {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}
.cognativex-banner1-image-container {
  display: flex;
  align-items: center;
}
[class*="cognativex-banner1-content"]:not(.cognativex-banner1-content1) {
  width: 50%;
}
.cognativex-banner1-block-title h3 {
  margin: 1% 1% 0.5%;
}
.cognativex-banner1-info {
  color: #fff;
  margin: 1% 0 4%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cognativex-feeds1-link,
.cognativex-feeds1-link:hover,
.cognativex-feeds1-link:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-feeds1-card a,
.cognativex-feeds1-card a:hover,
.cognativex-feeds1-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-feeds1-card {
  padding: 1%;
  width: 100%;
}
.cognativex-widget-recommendation-feed img {
  border-radius: 0;
}
.cognativex-feeds1-link img {
  width: 100%;
  filter: brightness(62%);
  -webkit-filter: brightness(62%);
  -webkit-transition: 0.5s -webkit-filter linear;
  -moz-transition: 0.5s -moz-filter linear;
  -moz-transition: 0.5s filter linear;
  -ms-transition: 0.5s -ms-filter linear;
  -o-transition: 0.5s -o-filter linear;
  transition: 0.5s filter linear, 1s -webkit-filter linear;
}
.cognativex-feeds1-link:hover img {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}
.cognativex-feeds1-styling {
  display: block;
  width: 100%;
}
.cognativex-feeds1-main-box {
  display: flex;
  flex-wrap: wrap;
}
.cognativex-feeds1-title {
  border: 1px solid #eee;
  padding: 1% 3%;
  margin-top: 2px;
}
.cognativex-feeds1-title * {
  margin: 0;
}
.cognativex-feeds1-contained1 {
  width: 60%;
}
.cognativex-feeds1-link {
  width: 100%;
}
.cognativex-feeds1-contained2 {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
}
.cognativex-feeds1-image-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.cognativex-feeds1-block-title h3 {
  margin: 1% 1% 0.5%;
}
.cognativex-feeds1-details p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 2px;
}
.cognativex-feeds1-details {
  margin: 1% 0 0;
}
.cognativex-feeds1-details * {
  margin: 0;
}
.cognativex-feeds1-image {
  width: 100%;
}
.cognativex-feeds1-image-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 36%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #eee;
}
.cognativex-bottom2-block-title h3 {
  margin: 1% 1% 0.5%;
}
.cognativex-bottom2-info {
  margin: 1% 0 2%;
}
.cognativex-bottom2-image {
  width: 100%;
}
.cognativex-bottom1-card a,
.cognativex-bottom1-card a:hover,
.cognativex-bottom1-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-bottom1-article-container {
  position: relative;
}
.cognativex-bottom1-title {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  padding: 0 2% 4%;
}
.cognativex-bottom1-title h5 {
  color: #fff;
  margin: 0;
}
.cognativex-bottom1-image-container:not(.placeholder-img) {
  width: 100%;
  filter: brightness(62%);
  -webkit-filter: brightness(62%);
  -webkit-transition: 0.5s -webkit-filter linear;
  -moz-transition: 0.5s -moz-filter linear;
  -moz-transition: 0.5s filter linear;
  -ms-transition: 0.5s -ms-filter linear;
  -o-transition: 0.5s -o-filter linear;
  transition: 0.5s filter linear, 1s -webkit-filter linear;
}
.cognativex-bottom1-link:hover img {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}
.cognativex-bottom1-image-container {
  box-shadow: 1px 1px 18px #ccc;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cognativex-bottom1-block-title h3 {
  margin: 1% 1% 0.5%;
}
.cognativex-bottom1-info {
  color: #fff;
  margin: 1% 0 4%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cognativex-bottom2-card a,
.cognativex-bottom2-card a:hover,
.cognativex-bottom2-card a:focus {
  text-decoration: none;
  color: #000;
}
.cognativex-bottom2-article-container {
  border: 1px solid #eee;
}
.cognativex-bottom2-date-text {
  color: #5b9caf;
}
.cognativex-bottom2-link h5 {
  color: #000;
  display: inline-block;
  margin: 0;
  border: 1px solid #eee;
  padding: 5px 16px;
  background-color: #fff;
  transition: background-color 0.5s ease;
  font-size: 82%;
}
.cognativex-bottom2-link:hover h5 {
  background-color: #f2f2f2;
}
.cognativex-bottom2-title > * {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.cognativex-bottom2-main-box {
  display: flex;
  flex-wrap: wrap;
}
.cognativex-bottom2-title {
  padding: 3%;
}
.cognativex-bottom2-contained1 {
  width: 60%;
}
.cognativex-bottom2-contained2 {
  display: flex;
  flex-wrap: wrap;
  width: 40%;
}
.cognativex-bottom2-image-container {
  display: flex;
  align-items: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding-top: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal-content .modal-body p {
  &.cognativex-bottom1-text,
  &.cognativex-bottom2-text {
    font-size: 82%;
    color: #5b9caf;
  }
}
.cognativex-banner1-container .cognativex-bottom1-image-container {
  padding-top: 82%;
}
.cognativex-bottom1-card,
.cognativex-bottom2-card {
  img {
    border-radius: 0;
  }
}

.cognativex-preview-container {
  width: 100%;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  justify-content: center;
  .cognativex-widget {
    margin-bottom: 40px;
  }
  h4 {
    padding-top: 10px;
    margin-bottom: 5px;
    text-align: left;
  }
  .border-placeholder {
    box-shadow: 1px 1px 5px #ccc;
    padding: 10px 10px 5px;
    margin-bottom: 10px;
    margin: 15px 0;
  }
  * {
    color: #222a42;
  }
  .cognativex-preview {
    width: 50%;
    box-shadow: 0px 2px 16px #222a42;
    padding: 2px;
  }
}

[class*="action-btns-"] {
  button {
    margin: 0 !important;
  }
}

.cognativex-modal {
  max-width: 100%;
  margin: 0;
  .modal-content {
    border-radius: 0;
    .modal-header {
      justify-content: center;
    }
  }
}
.navigate-status a {
  background: linear-gradient(rgba(31, 142, 241, 0), rgba(31, 142, 241, 0.2));
  padding: 12% 18%;
  font-size: 121%;
  box-shadow: 1px 1px 2px rgba(31, 142, 241, 0.2);
  border-radius: 6px;
  &:hover {
    background: $card-black-background;
  }
}
.cx-shadowed-table {
  .rt-table {
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.7);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin-bottom: 10px;
    button {
      font-size: 12px;
    }
  }
}
.cognativex-table-header {
  max-width: 88%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.cognativex-timeline-panel {
  width: 92% !important;
}
.cognativex-timeline {
  &:before {
    left: 100% !important;
  }
}
.cognativex-timeline-badge {
  left: 100% !important;
}

.ranges-min-avg-max {
  width: 90%;
  border-bottom: 2px solid #fff;
  position: relative;
  top: 0;
  margin: 0 auto;
  > span {
    border-left: 1px solid #fff;
    position: absolute;
    top: -4px;
    width: 100%;
    height: 6px;
    &.min {
      left: 0;
    }
    &.avg {
      left: 50%;
    }
    &.max {
      left: 100%;
    }
    .label {
      position: relative;
      right: 18px;
      top: 7px;
    }
    .text {
      position: relative;
      bottom: 16px;
      right: 4px;
    }
  }
}

.cx-pages-per-visit .highcharts-data-label {
  font-family: "Poppins", sans-serif;
}

.cognativex-tabs {
  min-width: 100px;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  background: #fff;
  justify-content: space-between;
  border-radius: 4px;
  overflow: hidden;
  > span {
    padding: 5px;
    color: #fff;
    &.active {
      background: $blue-color;
    }
    &:not(.active) {
      cursor: pointer;
      background: #525f7f;
    }
  }
}
.cx-pink {
  font-weight: 600;
  color: #fff;
}
.cx-white-color.active {
  color: #fff;
}
.card-right-part,
.card-left-part {
  color: rgb(112, 122, 148);
  display: block;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 8px;
}
@media (min-width: 970px) {
  .cognativex-schedule {
    .cognativex-schedule-desktop {
      display: block;
    }
    .cognativex-schedule-mobile {
      display: none;
    }
  }
}
.mb-0 {
  margin-bottom: 0px !important;
}
.cx-tahoma {
  font-family: Tahoma;
}
.cx-arabic {
  font-family: "Tajawal", sans-serif;
}
.cx-status {
  border-radius: 10px;
  padding: 4px 5px;
  font-weight: bold;
  font-size: 10px;
}
.cx-status-green {
  background-color: #d7f5ea;
  color: #1c5843;
}
.cx-status-yellow {
  background-color: #fbf8de;
  color: #4b4514;
}
.cx-status-blue {
  background-color: lightsteelblue;
  color: #375b8c;
}
.cx-status-orange {
  background-color: lightcoral;
  color: #792a2a;
}
.cx-status-red {
  background-color: red;
  color: #f0f0f0;
}

.cx-status-success{
  background-color: #0ca80c !important;
}
.cx-status-failure{
  background-color: #fd5d93 !important;
}

.progress-container span {
  font-size: 80% !important;
}
#authorsTable .rt-td,
#classesTable .rt-td,
#postsTable .rt-td,
#postsTableEngagement .rt-td {
  font-size: 90% !important;
}
#authorsTable .progress-container .progress .progress-bar .progress-value,
#classesTable .progress-container .progress .progress-bar .progress-value,
#postsTable .progress-container .progress .progress-bar .progress-value {
  top: -2px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 6px;
  padding-left: 6px;
}

.card {
  margin-bottom: 12px !important;
}

.card {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.cx-filter-bottom-alignment {
  margin-bottom: 12px;
}

.btn.selected-date-range-btn {
  margin: 0;
  border-radius: 6px;
}

.highcharts-container,
.highcharts-root {
  width: 100% !important;
}

.highchartscss {
  height: 400px;
}

.main-panel > .content {
  // padding: 20px 30px 30px 264px  !important;
}

.sidebar,
.off-canvas-sidebar {
  margin-left: 0px !important;
}

.cx-filtertabs {
  background-color: $card-black-background;
  border-radius: 5px;
  margin-bottom: 12px;
  margin-right: -9px;
  margin-left: -9px;
}

#campaigntable .rt-td {
  font-size: 90%;
}

.page-reactive-table {
  .cx-header-message {
    display: flex;
    //background: $lgrey-color;
    //color: $dblack-color;
    color: #fff;
    background-color: $grey-bar;
    padding: 8px 20px;
    border-radius: 5px;
    margin-bottom: 8px;
    i.cx-icon-msg {
      margin-right: 10px;
      &.fa-info {
        //border: 1.5px solid $dblack-color;
        border: 1px solid #fff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        &:before {
          position: relative;
          top: 2px;
        }
      }
    }
  }
  .cx-action-buttons {
    button:first-child:not(.cx-btn) {
      margin-left: 15px !important;
    }
  }
  .header-btn-container {
    margin-bottom: 1px;
    background: $card-color;
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: .3rem;
  }
  .ReactTable {
    &.cx-reactive-table {
      background: $card-color;
      * {
        //color: $lgrey-color;
        margin-bottom: 0;
        margin-top: 0;
      }
      a {
        color: $vlblue-color;
        &:hover {
          color: $lblue-color;
        }
      }
      .rt-tr {
        .table-actions-icons {
          visibility: hidden;
        }
        &:hover {
          .table-actions-icons {
            visibility: visible;
          }
        }
      }
      .rt-th {
        font-size: 109% !important;
      }
      .rt-td {
        font-size: 102% !important;
        .table-actions-icons * {
          color: $lgrey-color;
          border-bottom: 1px solid $lgrey-color;
          font-size: 90% !important;
        }
        .cx-table-image-styling {
          width: 40px;
          margin-right: 8px;
          i {
            font-size: 32px;
          }
          img {
            border: none;
            border-radius: 0;
          }
        }
        .cx-table-name-styling {
          width: 350px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &.ad {
            width: 300px;
          }
        }
      }
      .rt-th,
      .rt-td {
        border-color: $dblack-color !important;
      }
      .rt-tbody,
      .rt-thead.-filters {
        .rt-tr {
          border-color: $table-v-border-color !important;
        }
      }
    }
  }
}
.reactive-tabs {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  gap: 1px;
  .reactive-tab {
    position: relative;
    transition: background-color 0.5s ease;
    background: $inactive-tab-color;
    width: 25%;
    padding: 10px 15px;
    color: $lgrey-color;
    &:not(.active) {
      cursor: pointer;
      box-shadow: 0 -4px 5px -3px #0003 inset;
      &:hover {
        &:before {
          background-color: $dpurple-color;
        }
      }
    }
    &:before {
      height: 2px;
      content: " ";
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: background-color 0.2s ease-in-out;
    }
    &.active {
      &:before {
        background-color: $dblue-color;
      }
      color: $lblue-color;
      background: $card-color;
    }
  }
}
.reactive-tab__error{
  background-color: #b03c3c !important;
  animation: shake-tab-error .5s;
  box-shadow: 0 0 5px 0 #b03c3c !important;
}
@keyframes shake-tab-error {
  10%, 90% {
    transform: translateX(-1px);
  }
  
  20%, 80% {
    transform: translateX(2px);
  }

  30%, 50%, 70% {
    transform: translateX(-4px);
  }

  40%, 60% {
    transform: translateX(4px);
  }
}


.reactive-subtabs {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  flex-direction: column;
  .reactive-subtab {
    position: relative;
    background: $inactive-tab-color;
    margin: 2px 0;
    padding: 10px;
    font-size: 85%; // needs 15 to become 100 so add for .reactive-subtab-summary 100 + 15 to make it normal size
    &:not(.active) {
      cursor: pointer;
      &:hover {
        &:before {
          background-color: $dpurple-color;
        }
      }
    }
    &:before {
      height: 100%;
      content: " ";
      width: 2px;
      position: absolute;
      top: 0;
      left: 0;
      transition: background-color 0.2s ease-in-out;
    }
    &.active {
      &:before {
        background-color: $dblue-color;
      }
      color: $lblue-color;
      font-weight: 600;
    }
    .reactive-subtab-summary {
      font-size: 115%;
      color: $lblue-color;
      margin-left: 5px;
    }
  }
}
.cx-edit-drawer-form {
  .cx-action-buttons {
    box-shadow: 15px 0px 20px 0px $dblack-color;
    background-color: $card-color;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
  }
  .cx-form-msgs {
    font-size: 16px;
    color: #fff;
    opacity: 0;
    transition: 0.2s opacity ease-in;
    padding: 9px 20px 8px 0;
    font-weight: 300;
    &.show {
      opacity: 1;
    }
  }
}

.cx-single-datepicker {
  .cx-input-is-disabled {
    opacity: 0.5;
  }
  .cx-input-icon-container {
    .rdt {
      &:before {
        position: absolute;
        left: 9px;
        top: 8px;
        font-family: Font Awesome\5 Free;
        content: "\f073";
        font-size: 15px;
        display: block;
      }
    }
    input {
      padding: 5px 10px 5px 30px !important;
    }
  }
}
.cx-page-filter-container {
  .cx-action-buttons {
    text-align: right;
  }
}
.cx-filterbar-box {
  padding: 10px 15px;
  background: $inactive-tab-color;
  border-radius: 5px;
}
// todo temp should be removed when all other classes deleted
.cx-page-filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
  .cx-filter-page-by-date {
    width: 300px;
    .selected-date-range-btn {
      padding: 8px 30px;
      box-shadow: 1px 2px 7px 0px rgb(30, 32, 34);
    }
    .fa {
      margin-right: 5px;
      margin-top: 2px;
    }
  }
}
.cx-dates-container {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  label.cx-label-text {
    min-width: 40px;
    margin-right: 15px !important;
  }
  * {
    text-transform: capitalize;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &.middle-last {
    margin-top: 20px !important;
  }
  .cx-date-data {
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-flex;
    align-items: flex-end;
    .input-error,
    .input-warning {
      margin-right: 8px;
    }
  }
  .form-check-radio {
    .cx-rad-container {
      margin-bottom: 12px !important;
    }
  }
}

.cx-action-buttons {
  button:not(.cx-btn) {
    padding: 8px 10px;
    margin-right: 15px !important;
    margin-left: 0 !important;
    font-weight: 300;
    &[disabled],
    &:disabled {
      opacity: 0.3;
    }
    i {
      margin-right: 5px;
      font-size: 80%;
    }
    background: $grey-bar;
    color: #fff;
    transition: background-color 0.1s ease-in, color 0.1s ease-in;
    &:hover {
      background: $grey-bar;
    }
    &.cx-focus-btn {
      background: $dblue-color !important;
    }
  }
}

.cx-uploader-container {
  .cx-uploaded-image-preview {
    max-width: 120px;
    position: relative;
    img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 0;
    }
    .cx-uploaded-image-remove {
      cursor: pointer;
      border: none;
      outline: none;
      background: $dblack-color;
      padding: 0;
      font-size: 11px;
      display: flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      right: 0px;
      //border-radius: 50%;
      color: $lgrey-color;
      height: 20px;
      width: 20px;
      transition: color 0.2s ease;
      &:hover {
        color: #fff;
      }
    }
  }
}

.cx-edit-adset-devices {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  align-items: flex-end;
  * {
    margin-bottom: 0 !important;
  }
}

.cx-notification-alert div {
  z-index: 3000 !important;
}

.cx-input-container {
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  align-items: baseline;
  label {
    color: $lgrey-color;
    margin-right: 10px;
  }
  &.cx-uploader-container {
    .cx-uploader-icon-container {
      flex-direction: column;
      .cx-clone-uploader-icon-container {
        display: flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-flex;
        width: 100%;
        height: 33px;
        .input-like {
          width: 100%;
          height: 33px;
          padding: 5px 10px 5px 10px;
          border-radius: 6px;
          border: 1px solid $grey-bar;
          outline: none;
          background-color: transparent;
          &.with-icon {
            padding-left: 40px;
          }
        }
        &.error,
        &.warning {
          .input-like {
            border-color: $dred-color;
          }
        }
        &.warning {
          .input-like {
            border-color: $dorange-color;
          }
        }
        i {
          position: absolute;
          left: 13px;
          color: #9899a6;
          font-size: 16px;
          top: 8px;
        }
      }
      .cx-input-icon-input {
        height: 33px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 1;
        cursor: pointer;
      }
    }
  }

  .input-error {
    color: $dred-color;
  }

  .input-warning {
    color: $dorange-color;
  }

  .input-error,
  .input-warning,
  .input-description {
    width: 100%;
    margin-top: 5px;
    &:not(.show-msg) {
      display: none;
    }
  }
  .cx-input-icon-container {
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-wrap: wrap;
    ::placeholder {
      color: $lgrey-color;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $lgrey-color;
    }

    i {
      position: absolute;
      left: 10px;
      top: 8px;
      color: $lgrey-color;
      font-size: 16px;
    }
    input, textarea {
      width: 100%;
      padding: 5px 10px 5px 10px;
      border-radius: 6px;
      border: 1px solid $grey-bar;
      outline: none;
      background-color: transparent;
      color: #fff;
      transition: border-color 0.5s ease;
      &.with-icon {
        padding-left: 40px;
      }
      &.error {
        border-color: $dred-color;
      }
      &.warning {
        border-color: $dorange-color;
      }
    }
  }
}










.cx-popup-form-layout {
  width: 100%;
  .cx-input-label {
    width: 35%;
    max-width: 300px;
  }
  .cx-input-icon-container {
    width: 100%;
  }
  .react-select {
    width: 100%;
  }
}



.cx-quick-create-container {
  hr {
    margin: 25px 0;
    border-top: 1px solid $grey-bar !important;
  }
  .cx-text {
    color: $lgrey-color;
  }
}

.cx-popup {
  background-color: rgba($lgrey-color, 0.6);
  position: fixed;
  width: 100%;
  height: 0;
  top: 0;
  overflow: hidden;
  left: 0;
  z-index: 1500;
  justify-content: center;
  align-items: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  transition-delay: 0.2s;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: -webkit-flex;
  display: flex;
  &.open {
    height: 100%;
    opacity: 1;
  }
  .cx-action-buttons {
    display: flex;
    align-items: center;
  }
  .cx-loader-container {
    position: relative;
    right: 8px;
    top: 5px;
  }
}
.cx-popup > div:first-child{
  max-height: 90vh !important;
  margin-top: 7vh;
  overflow-y: auto;
}

$card-border-radius: 8px;
.cx-card-box-container {
  background-color: $card-color;
  border-radius: $card-border-radius;
  color: $lgrey-color;
  /*for header and body apply padding*/
  > * {
    padding: 10px 20px;
  }
  .cx-card-box-header {
    position: relative;
    background-color: $grey-bar;
    font-size: 16px;
    color: #fff;
    border-radius: $card-border-radius $card-border-radius 0 0;
    .close-btn {
      color: $lblue-color;
      font-size: 90%;
      position: absolute;
      right: 20px;
      transition: color 0.5s ease-out;
      cursor: pointer;
      &:hover {
        color: #fff;
      }
    }
  }
  .cx-card-box-body {
    position: relative;
    padding: 20px;
    font-size: 14px;
  }
}

.cx-popup-widget-form-addSchemaFieldBtn{
  background-color: $grey-bar;
  padding: 3px 9px;
  border-radius: 3px;
  font-size: 1.3rem;
  color: $white;
  border: none;
  margin-bottom: .5rem;
  transition: .5s;
  cursor: pointer;
  &:focus{
    outline: none;
  }
  &:hover{
    color: #90d4e8;
  }
}
.cx-popup-widget-form-numberOfWidgetsDisplay{
  margin-right: 10rem;
  color: rgba($lgrey-color, 0.6);
  & > span{
    color: $white;
  }
}
ul.cx-popup-widget-form-schemaFieldsList{
  list-style-type: none;
  width:100%;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 1rem;
  padding:0;
}
li.cx-popup-widget-form-widgetSchemaWindow{
  width: 40%;
  background-color: #28283d;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap:1.3rem;
  border-radius: 10px;
  box-shadow: inset -3px -3px 3px rgba(0, 0, 0, 0.2);
  position: relative;

  &>div{
    width:100%;
  }
  &>div>div{
    width:100% !important;
  }
  &>div>div>div{
    width:100%;
  }
}
li.cx-popup-widget-form-widgetSchemaWindow > button{
  width:15px;
  height:15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 15px;

  &:focus{
    outline: none;
  }
  &::after, &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width:2px;
    transition: .5s;
    height: 10px;
    background-color: #555861;
  }
  &::after{
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::before{
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover::after,
  &:hover::before{
    background-color: #5b9caf;
  }
}


@mixin etc($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cx-loader {
  width: 100%;
  height: 100%;
  background: url(~assets/img/loader.gif) no-repeat 50% 50%;
  z-index: 1000;
  background-size: 50px;
  top: 0;
  left: 0;
}

.cx-loader-container {
  position: relative;
  width: 30px;
  height: 30px;
  .cx-loader {
    background-size: 30px;
  }
}
.adset-form-segments-select {
  width: 100%;
  .cx-input-label {
    width: 28%;
  }
  .cx-input-icon-container {
    width: 100%;
  }
  .react-select {
    width: 100%;
  }
}

$drawer-width: 80%;
$tree-width: 445px;
$bar-width: 52px;
$tree-bar-height: 56px;
$action-bar-height: 63px;
.cx-drawer-container {
  overflow: hidden;
  background-color: transparent;
  transition: background-color 0.3s ease-in;
  &.open {
    .overlay {
      background-color: rgba($lgrey-color, 0.6);
      height: 100%;
      width: 100%;
      z-index: 1051;
      position: absolute;
    }
  }
  .cx-drawer-float {
    box-shadow: 0px 0px 2px 0px $dblack-color;
    background-color: $card-color;
    position: absolute;
    width: $drawer-width;
    right: -$drawer-width;
    height: 100%;
    transition: right ease-in-out 0.5s;
    z-index: 2000;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-flex;
    .cx-loader {
      background-color: rgba($card-color, 0.8);
    }
    .cx-drawer-actions {
      width: $bar-width;
      background-color: $grey-bar;
      text-align: center;
      > * {
        display: block;
        padding: 8px 0;
        transition: color 0.3s ease-out, background-color 0.3s ease-out;
        font-size: 22px;
        color: $dblack-color;
        &.cx-drawer-back {
          font-size: 27px;
          color: $lblue-color;
          &:hover {
            background-color: unset;
          }
        }
        &:hover,
        &.active {
          color: #fff;
          background-color: $card-color;
        }
      }
    }
    .cx-drawer-tree {
      width: $tree-width;
      position: relative;
      border-right: 1px solid $grey-bar;
      .cx-drawer-tree-content {
        height: calc(100% - 56px);
      }
      .cx-loader {
        background-position: 50% 18%;
      }
      .cx-drawer-tree-search {
        display: flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-flex;
        align-items: center;
        height: $tree-bar-height;
        border-bottom: 1px solid $grey-bar;
        position: relative;
        ::placeholder {
          color: $lgrey-color;
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: $lgrey-color;
        }

        i {
          position: absolute;
          left: 28px;
          color: $lgrey-color;
          font-size: 16px;
        }
        input {
          margin: 0 20px;
          width: 100%;
          padding: 5px 5px 5px 30px;
          border-radius: 6px;
          border: 1px solid $grey-bar;
          outline: none;
          background-color: transparent;
          color: #fff;
        }
      }
    }
    .cx-drawer-content {
      width: calc(100% - #{$tree-width} - #{$bar-width});
      > * {
        width: 100%;
        padding: 0 20px;
      }
      .cx-drawer-breadcrumb {
        text-transform: capitalize;
        box-shadow: 10px 0px 10px 0px $dblack-color;
        position: relative;
        z-index: 1;
        display: flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: -webkit-flex;
        align-items: center;
        height: $tree-bar-height;
        color: $lgrey-color;
        i {
          margin: 0 8px;
        }
        span:not(.count) {
          cursor: pointer;
          &:hover {
            color: #fff;
          }
          &.active {
            cursor: auto;
            color: $lblue-color;
          }
        }
      }
      .cx-drawer-content-inner {
        position: relative;
        overflow: hidden;
        background-color: $dblack-color;
        height: calc(100% - #{$tree-bar-height});
        .cx-drawer-allocation-form {
          height: 100%;
        }
        .cx-edit-campaign-form,
        .cx-edit-adset-form {
          &:not(.active) {
            display: none;
          }
          .cx-card-box-container.adForm{
            height: 78vh !important;
          }
          .cx-card-box-container {
            margin-top: 20px;
            display: inline-block;
            .cx-edit-campaign-input {
              &:not(:last-child) {
                margin-bottom: 20px;
              }
              .cx-input-label {
                width: 18%;
              }
              .cx-input-icon-container {
                width: 40%;
                .cx-input-icon-container-select {
                  width: 100%;
                  &.error {
                    .react-select__control {
                      border-color: $dred-color;
                    }
                  }
                  &.warning {
                    .react-select__control {
                      border-color: $dorange-color;
                    }
                  }
                }
              }
            }
            &.last-child {
              margin-bottom: 20px;
            }
          }
        }
        > form {
          overflow: hidden;
          height: calc(100% - #{$action-bar-height});
          .cx-edit-drawer-container {
            height: 100%;
          }
        }
        //performance
        .cx-drawer-inner-tabs {
          .cx-card-box-container {
            margin-top: 20px;
            display: inline-block;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            .cx-card-box-body {
              padding: 0;
              .reactive-tabs {
                .reactive-tab {
                  // temporary until we add more tabs
                  font-size: 0.875rem;
                  width: calc(33.33% - 3px);
                }
              }
              .cx-campaign-performance-body {
                padding: 15px;
                display: flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: -webkit-flex;
                flex-wrap: wrap;
                .cx-campaign-performance-subtabs {
                  width: calc(28% - 5px);
                  margin-right: 5px;
                }
                .cx-campaign-performance-subtabs-container {
                  width: 72%;
                }
              }
            }
          }
          &.cx-drawer-allocation-tabs-body {
            .cx-card-box-body {
              position: unset;
            }
          }
        }
        .cx-not-found {
          padding: 30px 15px;
          text-align: center;
          font-size: 16px;
          display: block;
        }
        // manage adset allocations - check adset summary
        .cx-campaign-adset-summary {
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          .cx-card-box-body {
            background-color: #28283d;
            padding: 15px !important;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            label {
              color: $grey-purple;
            }
          }
          > div {
            width: 48% !important;
          }
        }
        // manage adset allocations - check ads list
        .cx-campaign-adslist {
          max-height: calc(100vh - 148px);
          .cx-no-adlist-found {
            padding: 20px 20px;
            display: block;
          }
          .cx-campaign-adslist-container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 15px 15px 30px;
            .cx-adslist-box {
              position: relative;
              width: 31%;
              margin-left: 1%;
              margin-right: 1%;
              margin-top: 15px;
              > a {
                display: flex;
                width: 100%;
                flex-direction: column;
                color: $lgrey-color;
                i {
                  color: $lblue-color;
                }
              }
              .cx-adslist-box-image {
                height: 150px;
                width: auto;
                background-position: center;
                background-size: cover;
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
              }
              .cx-adlist-box-content {
                background: $dblack-color;
                padding: 15px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                label {
                  font-weight: 500;
                  color: $grey-purple;
                  margin-bottom: 0;
                }
                .cx-adlist-box-advertiser {
                  display: flex;
                  .cx-adlist-box-advertiser-name {
                    max-width: calc(100% - 41px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
                .cx-adlist-box-title {
                  display: flex;
                  .cx-adlist-box-title-text {
                    max-width: calc(100% - 60px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
              .cx-adlist-box-status {
                position: absolute;
                top: 0;
                right: 0;
                background: $dblack-color;
                padding: 0px 6px;
                border-radius: 0px 8px 0px 8px;
                .cx-table-ico-color {
                  margin-right: 5px;
                }
              }
            }
          }
        }
        // manage adset allocations - set allocation for the adset
        .cx-campaign-adset-allocation {
          max-height: calc(100vh - 150px);
          .cx-campaign-adset-allocation-table-like {
            padding: 15px 0;
            display: flex;
            flex-direction: column;
            .cx-table-like-container {
              display: flex;
              &:not(:last-child) {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
              }
              &.cx-table-like-header {
                font-weight: 600;
                font-size: 110%;
              }
              > div {
                width: 33%;
                padding: 10px 20px;
                &:not(:first-child):not(:last-child) {
                  border-left: 1px solid $dblack-color;
                  border-right: 1px solid $dblack-color;
                }
              }
            }
          }
        }
      }
    }
  }
  &.open {
    .cx-drawer-float {
      right: 0;
    }
  }

  &.cx-media-planner-drawer {
    .cx-drawer-float {
      width: 60%;
      .cx-drawer-content {
        width: calc(100% - 52px);
      }
    }
  }
}
.cx-check-desktop {
  .cx-checkbox-label-value {
    visibility: hidden;
  }
}

.cx-tree-container {
  position: relative;
  color: $lgrey-color;
  > * {
    margin-bottom: 15px;
  }
  .cx-tree-type-name {
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-flex;
    align-items: center;
    margin-right: 23px;
    .cx-tree-name-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
  .cx-tree-level-1 > .cx-tree-type-name {
    @include etc(280px);
  }
  .cx-tree-level-2 > .cx-tree-type-name {
    @include etc(252px);
  }
  .cx-tree-level-3 > .cx-tree-type-name {
    @include etc(224px);
  }
  .cx-tree-type-name-options {
    position: relative;
    .cx-tree-more-options {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      right: 10px;
      margin-top: -26px;
      z-index: 10;
      transition: opacity 0.2s ease-in;
      &.open {
        opacity: 1;
        i {
          color: $lblue-color;
        }
      }
    }
    .cx-tree-options-values {
      position: absolute;
      right: 15px;
      width: 135px;
      top: 15px;
      z-index: 2;
      font-size: 12px;
      -webkit-box-shadow: 0px 0px 10px 5px $dblack-color;
      -moz-box-shadow: 0px 0px 10px 5px $dblack-color;
      box-shadow: 0px 0px 10px 5px $dblack-color;
      > div {
        padding: 5px 10px;
        cursor: pointer;
        color: $dblack-color;
        background: #fff;
        transition: background-color 0.2s ease;
        &:hover {
          color: #fff;
          background-color: $grey-bar;
        }
      }
    }
    &:hover {
      .cx-tree-more-options {
        opacity: 1;
      }
    }
  }

  [class^="cx-tree-level-"] {
    margin-left: 25px;
    i {
      margin-right: 5px;
    }
    &.active {
      &:before {
        position: absolute;
        content: " ";
        background-color: #3e3e5c;
        width: 100%;
        left: 0;
        height: 30px;
        z-index: -1;
      }
      > .cx-tree-type-name-options > .cx-tree-type-name {
        color: $lblue-color;
        cursor: auto;
      }
    }
  }
}

.cx-filtertabs {
  background-color: $card-black-background;
  border-radius: 5px;
  > span {
    position: relative;
    padding: 10px 20px;
    &:before {
      transition: background-color 0.5s ease, width 0.2s ease-in;
      height: 2px;
      content: " ";
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:not(.active) {
      cursor: pointer;
      &:before {
        width: 0;
        background-color: $blue-color;
      }
      &:hover {
        &:before {
          width: 100%;
        }
      }
    }
    &.active {
      &:before {
        width: 100%;
        background-color: $lpurple-color;
      }
    }
  }
}

.cx-admediaplanner-review {
  .cx-truncate-adsurl {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.cx-error {
  color: $dred-color;
}

.cx-publish-all-container {
  .cx-tooltip {
    cursor: pointer;
  }
}

.cx-campaign-adset-preview {
  display: flex;
  .cx-campaign-adslist-container {
    padding-top: 0 !important;
  }
  .cx-adslist-box {
    width: 100% !important;
    margin: 0 !important;
  }
  .cx-input-icon-container {
    width: 80% !important;
  }
}

.cx-quick-create-campaing {
  .btn-primary,
  .btn-primary:active,
  .btn-primary:focus,
  .btn-primary:active:hover {
    background: $grey-bar !important;
    padding: 9px 12px !important;
    border-radius: 2px !important;
    margin: 0 !important;
  }
  .btn-primary:hover {
    color: rgba(255, 255, 255, 0.5) !important;
    background: $grey-bar !important;
  }
  .cx-input-icon-container {
    width: 65% !important;
  }
  .cx-dropitems {
    margin-bottom: 20px;
  }
  .cx-dropitems-inputs {
    margin-left: 30px;
  }
  .dropdown-menu {
    -webkit-transition: none !important;
    transition: none !important;
  }
  .dropdown-toggle {
    font-size: 12px;
    font-weight: normal;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-menu:before {
    content: normal !important;
  }
  .cx-input-label {
    text-align: right;
  }
  .cx-input-container {
    label {
      margin-right: 20px;
    }
    .cx-input-icon-container > input {
      padding: 9px 10px 9px 10px !important;
    }
  }
}

.cx-advanced-filter {
  display: flex;
  flex-wrap: wrap;
  .cx-advanced-filter-title {
    padding: 4px 0;
    color: $lgrey-color;
  }
  .dropdown {
    button {
      background: $grey-bar !important;
      box-shadow: unset;
      margin: 0;
      display: flex;
      padding: 5px 10px;
      align-items: center;
      &:hover {
        color: rgba(#fff, 0.5) !important;
        background: $grey-bar !important;
      }
    }
    #dropdown-filter {
      padding: 8px;
      &:after {
        content: unset;
      }
    }
    .dropdown-menu {
      padding: 0.2rem 0 !important;
      max-height: 376px;
      overflow-y: auto;
    }
  }
  .dropdown-item {
    margin-top: 0 !important;
  }
  .dropdown-menu {
    -webkit-transition: none !important;
    transition: none !important;
    &:before {
      content: normal !important;
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  > div:not(:last-child) {
    margin-right: 15px;
  }
  .cx-advanced-filter-choose {
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 13px;
    height: 30px;
    border: 1px solid rgba($lgrey-color, 0.5);
    background-color: $card-color;
    > div {
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      &:not(:last-child) {
        border-right: 1px solid rgba($lgrey-color, 0.5);
      }
      &.cx-advanced-filter-title {
        color: $lgrey-color;
      }
      .cx-advanced-filter-text-add,
      .cx-advanced-filter-checkboxes-add {
        color: #fff;
      }
      &.cx-advanced-filter-value {
        > div:not(:last-child) {
          margin-right: 10px;
        }
        .cx-advanced-filter-text-add,
        .cx-advanced-filter-checkboxes-add {
          margin-left: 10px;
          color: $vlblue-color;
        }
        .cx-advanced-filter-cancel {
          color: $lblue-color;
        }
        .cx-advanced-filter-text-input {
          background: unset;
          border: 1px solid $lgrey-color;
          width: 94px;
          padding: 0px 5px;
          color: #fff;
          border-radius: 5px;
          outline: none;
          font-size: 12px;
        }
        .dropdown {
          display: flex;
          align-items: center;
        }
        .dropdown-toggle {
          border: 1px solid $lgrey-color;
          padding: 0px 5px;
          color: #fff;
          border-radius: 5px;
          outline: none;
          max-width: 300px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .dropdown-toggle {
        background: unset !important;
        padding-left: 0;
        padding-right: 0;
        font-weight: 400;
        color: #fff;
      }
      .dropdown-menu {
        margin-left: -10px;
      }
    }
  }
  .cx-advanced-filter-selected-item-container {
    padding: 0 0 4px;
    margin-right: 10px;
    .cx-advanced-filter-selected-item {
      height: 30px;
      font-size: 13px;
      color: $lgrey-color;
      vertical-align: middle;
      display: table-cell;
      white-space: nowrap;
      border-radius: 8px;
      border: 1px solid rgba($lgrey-color, 0.5);
      padding: 0 10px;
      background-color: $card-color;
      > *:not(:last-child) {
        margin-right: 8px;
      }
      .cx-advanced-filter-selected-item-value {
        font-weight: 500;
        font-size: 14px;
        color: #fff;
      }
      .cx-advanced-filter-selected-item-delete {
        color: $lblue-color;
      }
      .cx-advanced-filter-selected-item-title {
        margin-right: 3px;
      }
    }
  }
}

.cx-dropitems {
  .dropdown {
    .dropdown-menu {
      padding: 0.2rem 0 !important;
    }
  }
  .dropdown-item {
    margin-top: 0 !important;
  }
}

.cx-dropitems .dropdown-menu .dropdown-item,
.bootstrap-select .dropdown-menu.inner li a {
  font-size: 0.75rem;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
  -webkit-transition: all 150ms linear;
  transition: all 150ms linear;
}

.m-0-imp {
  &,
  &.btn {
    margin: 0 !important;
  }
}

.cx-page-widget-performance {
  .cx-summary-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    .cx-summary-box {
      background-color: $card-color;
      width: 16%;
      padding: 15px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      .cx-summary-title {
        text-transform: capitalize;
        color: $light-gray;
      }
      .cx-summary-value {
        color: $lblue-color;
      }
    }
  }
}

.cx-card-look {
  background: $card-color;
  margin-top: 15px;
  border-radius: 5px;
}
.cx-widget-performance-graphs {
  .cx-card-look {
    &.col-sm-6 {
      width: 49.5%;
      max-width: 49.5%;
      &.cx-card-look-left {
        margin-right: 0.5%;
      }
      &.cx-card-look-right {
        margin-left: 0.5%;
      }
    }
  }
}

.cx-onboarding-page {
  padding: 15px;
  .cx-onboarding-title {
    font-size: 20px;
    color: $lblue-color;
  }
  .cx-onboarding-content {
    .cx-onboarding-text p {
      margin-bottom: 15px;
      color: $lgrey-color;
    }
    .cx-onboarding-bubbles {
      display: flex;
      $bubble-size: 182px;
      > div {
        position: relative;
        background-color: white;
        font-weight: 700;
        width: $bubble-size;
        height: $bubble-size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 40px;
        &:not(.cx-onboarding-bubble-3) {
          &:after {
            content: "";
            width: 45px;
            height: 5px;
            position: absolute;
            right: -47px;
          }
        }
      }
      .cx-onboarding-bubble-1 {
        border: 5px solid $dblue-color;
        color: $dblue-color;
        &:after {
          background: linear-gradient(
            to right,
            $dblue-color,
            50%,
            $dpurple-color
          );
        }
      }
      .cx-onboarding-bubble-2 {
        border: 5px solid $dpurple-color;
        color: $dpurple-color;
        &:after {
          background: linear-gradient(
            to right,
            $dpurple-color,
            50%,
            $lorange-color
          );
        }
      }
      .cx-onboarding-bubble-3 {
        border: 5px solid $lorange-color;
        color: $lorange-color;
      }
    }
  }
  .cx-onboarding-form {
    .cx-metadata-details-container {
      .cx-metadata-details-item {
        margin-right: 25px;
        .cx-metadata-details-title {
          margin-bottom: 5px;
        }
        i {
          margin-right: 5px;
        }
      }
    }
    &.cx-addinfo {
      .cx-input-container {
        .cx-input-icon-container {
          width: 60%;
        }
      }
      .cx-addinfo-packages {
        display: flex;
        .cx-addinfo-package {
          width: 258px;
          margin-right: 15px;
          .cx-addinfo-package-title {
            transition: color 0.2s ease-in, background-color 0.2s ease-in;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background: $lgrey-color;
            color: $dblack-color;
            padding: 7px 12px;
            font-weight: 600;
            i {
              transition: opacity 0.5s ease-in;
              opacity: 1;
              margin-right: 5px;
              font-size: 110%;
            }
          }
          .cx-addinfo-package-content {
            transition: border-color 0.2s ease-in;
            height: 93px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 15px 0px;
            background: $dblack-color;
            border: 2px solid $dblack-color;
            border-top-width: 0;
            ul {
              margin: 0;
              &,
              li {
                color: $lgrey-color;
              }
            }
          }
          &:not(.active) {
            cursor: pointer;
            .cx-addinfo-package-title {
              i {
                opacity: 0;
              }
            }
          }
          &.active {
            .cx-addinfo-package-title {
              background: $dblue-color;
              color: #fff;
            }
            .cx-addinfo-package-content {
              border-color: $dblue-color;
            }
          }
        }
      }
    }
    &.cx-verify-metadata {
      .cx-input-container {
        width: 45%;
        margin-right: 20px;
        .cx-input-icon-container {
          width: 100%;
        }
      }
    }
    &.cx-add-users {
      .cx-add-more {
        color: $dblue-color;
        transition: color 0.1s ease-in;
        &:hover {
          color: $lblue-color;
        }
      }
    }
    &.cx-onboarding-finish {
      a {
        color: $dblue-color;
        &:hover {
          color: $lblue-color;
        }
      }
    }
    hr {
      border-color: $grey-bar;
    }
  }
}

.cx-add-users {
  .cx-users-array-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    .cx-input-container {
      margin-right: 12px;
    }
  }
}

.cx-d-green-color {
  color: $dgreen-color;
}
.cx-white-color {
  color: #fff;
}
.cx-d-orange-color {
  color: $dorange-color;
}
.cx-d-red-color {
  color: $dred-color;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border-color: #555861 !important;
  margin-left: 0 !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}








// new styles for widget form

.widget-form-tabs > *,
.publisher-form-tabs > *{
  flex-grow: 1;
}
.widget-form-tabs i{
  vertical-align: baseline;
}
.publisher-form-tabs p{
  display: inline-block;
}
.widget-form-tabs p{
  display: inline-block;
  transform: translate(5px, -2px);
}

// new styles for widget form

                          
.searchOrganizationsInput{
  display: flex;
  align-items: baseline;
  & > input{
    color:rgba(255, 255, 255, 0.8);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(127, 127, 133, 0.8);
    padding-bottom: .4rem;
    padding-left: .5rem;
    &:focus{
      outline:none;
    }
  }
  & > i{
    font-size: .8rem !important;
    color:rgba(127, 127, 133, 0.8);
  }
}


.cx-no-data-svg{
  width: 500px;
  margin-inline: auto;
  opacity: .8;
  display: block;
}

















////////////////////////////////////////////////////////////////////
// CX components
.cx-btn{
  background-color: #4b4c6e !important;
  padding: .5rem 1.2rem !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 1px 2px 0 #0002 !important;
  text-align: center !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: .4s !important;
  user-select: none !important;
  width: fit-content;
}
.cx-btn:focus{
  outline:none;
}
.cx-btn:hover{
  transform: translateY(-3px) !important;
  box-shadow: 0 4px 8px 0 #0002 !important;
}


//cx-popup
.cx-popup-card{
  position: fixed;
  min-width: 200px;
  background-color: #3e3e5c;
  border-radius: 5px;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -70%);
  box-shadow: 0px 10px 10px -7px #0003;
  overflow: hidden;
  z-index: 9999;
  animation: slide-popup-in .4s forwards;
}
.cx-popup-card-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0001;
  inset: 0;
  z-index: 9999;
}
@keyframes slide-popup-in {
  to{
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}


//cx-confirm
.cx-popup-confirm{
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  background-color: #35364d;
  color: white;
  max-width: 600px;
}
.cx-popup-confirm__title{
  margin-bottom: 1.5rem !important;
}
.cx-popup-confirm__title h3{
  margin-bottom: .3rem !important;
}
.cx-popup-confirm__title p{
  opacity: .65;
}

.cx-popup-confirm__actions{
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  gap: 1rem;
}





//cx-input
.cx-input{
  background-color: #4b4c6e;
  color: white;
  border-radius: 5px;
  padding: .3rem .8rem;
  border: none;
  &:focus{
    outline: none
  }
}



//cx-radio
.cx-radio-group{

  &__title{
    margin-bottom: .5rem;
  }

  &__options{
    display: flex;
    flex-wrap: wrap;
    gap: 1px;

    &__item{
      &:first-of-type label{
        border-radius: 5px 0 0 5px;
      }
      &:last-of-type label{
        border-radius: 0 5px 5px 0;
      }
    }

    &__item label{
      width: fit-content;
      padding: .2rem .6rem;
      color: white;
      background-color: #4b4c6e;
      font-size: 1rem;
      cursor: pointer;
      transition: .3s;
   }

    &__item input:checked + label{
      background-color: #21c8df;
      color: black;
      box-shadow: 0 0 15px 0 #21c8df77;
      font-weight: 700;
    }
  }

}





//cx-pill
.cx-pill{
  border-radius: 999px;
  background: linear-gradient(to right, #ef535c, #8e3ccc);
  color: white;
  padding: .2rem .8rem;
  display: flex;
  align-items: center;
  gap: .4rem;
  cursor: default;
  width: fit-content;
  position: relative;
  overflow: hidden;

  &__close{
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to left, #1e1e2d, transparent);
    display: grid;
    place-items: center;
    pointer-events: none;
    transition: .2s;
    padding: 0 10px 0 30%;

    i{
      cursor: pointer;
      width: 10px;
    }
  }
  &:hover &__close{
    opacity: 1;
    pointer-events: all;
  }
}
.cx-pill-disabled{
  background: linear-gradient(to right, #ef535c, #8e3ccc);
  opacity: .2;
  pointer-events: none;
  cursor: not-allowed;
}


//btns
.cx-remove-permission-btn{
  background-color:#b03c3c;
}


//cx-icon-btn
.cx-icon-btn{
  padding: .35rem .5rem;
  border: none;
  cursor: pointer;
  transition: .4s;
  user-select: none;
  background-color: transparent;
  color: white;
}
.cx-icon-btn:focus{
  outline: none;
}



//drawer
.cx-drawer-wrapper{
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.cx-overlay{
  position: absolute;
  z-index: 990;
  inset: 0;
  background-color: #0005;
}

.cx-drawer{
  position: absolute;
  left: 100%;
  top: 0;
  height: 100vh;
  z-index: 9000;
  transition: .5s;
  background-color: #222a42;
  animation: slide-drawer-in .5s forwards;
  box-shadow: -10px 0 6px 0 #0003;
  

  &__header{
    width: 100%;
    padding: 15px 50px;
    color: #a7aab5;
    background-color: #4b4c6e;
    margin-bottom: 1rem;
  }

  &__body{
    padding: 0 50px 50px 50px;
    overflow: auto;
    max-height: 92vh;
    min-height: 92vh;

    &::-webkit-scrollbar{
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track{
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #6d6e9e;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover{
      background-color:  #4b4c6e
    }
    &::-webkit-scrollbar-corner{
      background-color: transparent;
    }
  }
}



@keyframes slide-drawer-in{
  to{
    transform: translateX(-100%);
  }
}
@keyframes slide-drawer-out{
  0%{
    transform: translateX(-100%);
  }
  50%{
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(20%);
  }
}
@keyframes rotate-drawer-arror{
  to{
    rotate: 0deg;
  }
}

.cx-drawer-close-btn{
  background-color: #21c8df;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(50%, -50%);
  display: grid;
  place-items: center;
  border: 12px solid #222a42;
  box-shadow: -10px 0 6px 0 #0003;
  cursor: pointer;
}
.cx-drawer-close-btn i{
  color: #222a42;
  font-size: 1.6rem;
  transition: .3s;
  rotate: 180deg;
  animation: rotate-drawer-arror 300ms forwards;
  animation-delay: 500ms;
}
.cx-drawer-close-btn:hover i{
  color: white;
}



// CX Select
:root{
  --gray: rgb(172, 169, 169);
  --dark-gray: #4b4c6e;
  --cx-select-padding: .5rem 1rem;
  --success-color: #4090b4;
  --danger-color: #fd5d93;
}

.cx-select{
  user-select: none;
  position: relative;
  width: 200px;
  border-radius: 5px;
}

.cx-select-open.cx-select{
  box-shadow: 0 4px 8px 0 #3333;
}


.cx-select__trigger{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-gray);
  border-radius: 5px;
  padding: var(--cx-select-padding);
  color: white;
  cursor: pointer;
  width:100%;
}
.cx-select-open .cx-select__trigger{
  border-radius: 5px 5px 0 0;
  color: var(--dark-gray);
  background-color: white;
} 
.cx-select__trigger::after{
  content: '';
  position: absolute;
  width: 0;
  background-color: var(--gray);
  display: block;
  margin-inline:auto;
  height: 1px; 
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.cx-select-open .cx-select__trigger::after{
  transition: .5s;
  width: 90%;
}
.cx-select__placeholder{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 92%
}

.cx-select__arrow{
  position: relative;
  height: 8px;
  transform-origin: center;
  width: 6px;
}

.cx-select__arrow::after,
.cx-select__arrow::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  border-radius: 50px;
  transition: .45s;
  background-color: white;
}
.cx-select__arrow::after{
  left: 0;
  transform: rotate(-40deg);
}
.cx-select__arrow::before{
  right: 0;
  transform: rotate(40deg);
}

.cx-select-open .cx-select__arrow::after{
  transform: rotate(40deg);
  background-color: var(--dark-gray);
}
.cx-select-open .cx-select__arrow::before{
  background-color: var(--dark-gray);
  transform: rotate(-40deg);
}

.cx-select__options{
  width: 100%;
  height: 200px;
  padding: 0;
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 2000;
  opacity: 0;
  transform: translateY(-20px);
  animation: cx-select-slide-options-in .5s forwards;
}

.cx-select__options::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
.cx-select__options::-webkit-scrollbar-track{
  background-color: white;
}
.cx-select__options::-webkit-scrollbar-thumb{
  background-color: var(--gray);
  border-radius: 5px;
}
.cx-select__options::-webkit-scrollbar-thumb:hover{
  background-color: var(--dark-gray);
}
.cx-select__options::-webkit-scrollbar-corner{
  background-color: white;
}

.cx-select__option{
  list-style-type: none;
  padding: var(--cx-select-padding);
  cursor: pointer;
  width: 100%;
  color: var(--dark-gray);
  background-color: white;
}
.cx-select__option:hover{
  background-color: var(--dark-gray);
  color: white;
}
.cx-select__option:last-of-type{
  border-radius: 0 0 5px 5px;
}
.cx-select__active-option{
  // background-color: var(--active-gray);
  // color: white;
  font-weight: 900;
}
@keyframes cx-select-slide-options-in {
  to{
      transform: translateY(0);
      opacity: 1;
  }
}



//CX Multi Select

:root{
  --gray: rgb(172, 169, 169);
  --dark-gray: #4b4c6e;
  --cx-multi-select-padding: .5rem 1rem;
  --cx-multi-select-padding-option: .5rem 1rem 0.5rem 2rem;
}

.cx-multi-select{
  user-select: none;
  position: relative;
  width: 200px;
  border-radius: 5px;
}

.cx-multi-select-open.cx-multi-select{
  box-shadow: 0 4px 8px 0 #3333;
}


.cx-multi-select__trigger{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--dark-gray);
  border-radius: 5px;
  padding: var(--cx-multi-select-padding);
  color: white;
  cursor: pointer;
  width:100%;
}
.cx-multi-select-open .cx-multi-select__trigger{
  border-radius: 5px 5px 0 0;
  color: var(--dark-gray);
  background-color: white;
} 

.cx-multi-select__search{
  background-color: white;
  width: 100%;

  input{
    width: 88%;
    margin: 1rem;
    border: none;
    background-color: transparent;
  }
  input:focus{
    outline: none
  }
}



.cx-multi-select__placeholder{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cx-multi-select__trigger::after{
  content: '';
  position: absolute;
  width: 0;
  background-color: var(--gray);
  display: block;
  margin-inline:auto;
  height: 1px; 
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.cx-multi-select-open .cx-multi-select__trigger::after{
  transition: .5s;
  width: 96%;
}

.cx-multi-select__arrow{
  position: relative;
  height: 8px;
  transform-origin: center;
  width: 6px;
}

.cx-multi-select__arrow::after,
.cx-multi-select__arrow::before{
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  border-radius: 50px;
  transition: .45s;
  background-color: white;
}
.cx-multi-select__arrow::after{
  left: 0;
  transform: rotate(-40deg);
}
.cx-multi-select__arrow::before{
  right: 0;
  transform: rotate(40deg);
}

.cx-multi-select-open .cx-multi-select__arrow::after{
  transform: rotate(40deg);
  background-color: var(--dark-gray);
}
.cx-multi-select-open .cx-multi-select__arrow::before{
  background-color: var(--dark-gray);
  transform: rotate(-40deg);
}

.cx-multi-select__options{
  width: 100%;
  height: 200px;
  padding: 0;
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 2000;
}

.cx-multi-select__options::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
.cx-multi-select__options::-webkit-scrollbar-track{
  background-color: white;
}
.cx-multi-select__options::-webkit-scrollbar-thumb{
  background-color: var(--gray);
  border-radius: 5px;
}
.cx-multi-select__options::-webkit-scrollbar-thumb:hover{
  background-color: var(--dark-gray);
}
.cx-multi-select__options::-webkit-scrollbar-corner{
  background-color: white;
}

.cx-multi-select__option{
  list-style-type: none;
  padding: var(--cx-multi-select-padding-option);
  cursor: pointer;
  width: 100%;
  color: var(--dark-gray);
  background-color: white;
  opacity: 0;
  animation: cx-multi-select-slide-option-in .5s forwards;
}
.cx-multi-select__option:hover{
  background-color: var(--dark-gray);
  color: white;
}
.cx-multi-select__option:last-of-type{
  border-radius: 0 0 5px 5px;
}
.cx-multi-select__active-option{
  position: relative;
  font-weight: 900;
}
.cx-multi-select__active-option::after{
  position: absolute;
  content: "\2713";
  left: 11px;
  top: 50%;
  transform: translateY(-50%);
}
@keyframes cx-multi-select-slide-option-in {
  to{
      transform: translateY(0);
      opacity: 1;
  }
}


//cx select with pills

.cx-select-w-pills-container{
  display: flex;
  align-items: center;
  gap: .7rem;
  color: whitesmoke;
}

.cx-select-w-pills__pillsContainer{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
}

.cx-select-w-pills__selectContainer{
  position: relative;
}

.cx-select-w-pills__selectContainer__trigger{
  background-color: #454c61 !important;
  width: 35px !important;
  height: 35px !important;
  display: grid;
  place-items: center;
  border-radius: 50% !important;
  transition: .3s !important;
}
.cx-select-w-pills__selectContainer__trigger:hover{
  transform: none !important;
}
.cx-select-w-pills__selectContainer__trigger:hover > i{
  color: #55c0f0  !important;
}
.cx-select-w-pills__selectContainer__trigger > i{
  font-size: 1.3rem  !important;
  color: whitesmoke  !important;
  transition: .5s  !important;
}
.cx-select-w-pills-open .cx-select-w-pills__selectContainer__trigger > i{
  transform: rotate(45deg)  !important;
}


.cx-select-w-pills__selectContainer__popup{
  position: absolute;
  z-index: 4000;
  top: 135%;
  background-color: #454c61;
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 #0004;
  left: 50%;
  translate: -50% -10px;
  opacity: 0;
  animation: slide-popup-select-in .5s forwards;
  width:400px;
  min-width: 180px;
}
.cx-select-w-pills__selectContainer__popup ul::-webkit-scrollbar{
  display: none;
}
.cx-select-w-pills__selectContainer__popup ul{
  margin-top: 60px;
  max-height: 250px;
  overflow: auto;
  list-style-type: none;
  padding-left: 0;
}
.cx-select-w-pills__selectContainer__popup .fa-caret-up{
  position: absolute;
  top: -17px;
  left: 50%;
  translate: -50% 0;
  color: #454c61;
  font-size: 2rem;
}
.cx-select-w-pills__selectContainer__popup ul li{
  padding: .5rem 1rem;
  transition: .5s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cx-select-w-pills__selectContainer__popup ul li:hover{
  background-color: #575d6c;
}
.cx-select-w-pills__selectContainer__popup > input{
  font-size: 1rem;
  color: rgb(207, 207, 207);
  background-color: transparent;
  padding: .3rem .45rem;
  border: 1px solid currentColor;
  border-radius: 5px;
  position: fixed;
  inset: 1rem 1rem auto 1rem;
  margin-bottom: 1rem;

  &:focus{
    outline: none
  }
}


@keyframes slide-popup-select-in {
  to{
      opacity: 1;
      translate: -50% 0;
  }
}






:root{
  --gray: rgb(172, 169, 169);
  --light-gray: rgb(225, 223, 223);
  --dark-gray: #4b4c6e ;
}

.cx-advanced-select{
  user-select: none;
  position: relative;
  width: 200px;
  border-radius: 5px;
}


.cx-advanced-select__trigger{
  position: relative;
  min-width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .3rem .8rem;
  background-color: var(--dark-gray);
  border-radius: 5px;
  color: var(--dark-gray);
  cursor: pointer;
  width:100%;
}
.cx-advanced-select__placeholder{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  padding: .2rem ;
}

.cx-advanced-select__arrow{
  position: relative;
  height: 8px;
  transform-origin: center;
  width: 6px;
  min-width: 6px;
}

.cx-advanced-select__arrow::after,
.cx-advanced-select__arrow::before{
  content: '';
  position: absolute;
  height: 100%;
  background-color: white;
  width: 2px;
  border-radius: 50px;
  transition: .45s
}
.cx-advanced-select__arrow::after{
  left: 0;
  transform: rotate(-40deg);
}
.cx-advanced-select__arrow::before{
  right: 0;
  transform: rotate(40deg);
}

.cx-advanced-select-open .cx-advanced-select__arrow::after{
  transform: rotate(40deg);
}
.cx-advanced-select-open .cx-advanced-select__arrow::before{
  transform: rotate(-40deg);
}

.cx-advanced-select__options{
  width: 100%;
  height: 200px;
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 20;
}

.cx-advanced-select__options::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
.cx-advanced-select__options::-webkit-scrollbar-track{
  background-color: var(--light-gray);
}
.cx-advanced-select__options::-webkit-scrollbar-thumb{
  background-color: var(--gray);
  border-radius: 5px;
}
.cx-advanced-select__options::-webkit-scrollbar-thumb:hover{
  background-color: var(--dark-gray);
}
.cx-advanced-select__options::-webkit-scrollbar-corner{
  background-color: var(--light-gray);
}


.cx-advanced-select__selectContainer__popup{
  position: absolute;
  z-index: 4000;
  top: 140%;
  background-color: var(--dark-gray);
  border-radius: 5px;
  box-shadow: 0 5px 10px 0 #0004;
  left: 0;
  translate: 0 10px;
  opacity: 0;
  animation: slide-popup-select-advanced-in .5s forwards;
  min-width:fit-content;
  width: 350px;
}
.cx-advanced-select__selectContainer__popup ul::-webkit-scrollbar{
  display: none;
}
.cx-advanced-select__selectContainer__popup ul{
  margin-top: 60px;
  max-height: 250px;
  overflow: auto;
  list-style-type: none;
  padding: 0;
}
.cx-advanced-select__selectContainer__popup .fa-caret-up{
  position: absolute;
  top: -17px;
  left: 20px;
  color: #454c61;
  font-size: 2rem;
}
.cx-advanced-select__selectContainer__popup ul li{
  padding: .5rem 1rem;
  transition: .5s;
  cursor: pointer;
  color:rgb(225, 223, 223);
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
}
.cx-advanced-select__selectContainer__popup ul li span{
  margin-left: auto
}
.cx-advanced-select__selectContainer__popup ul li:hover{
  background-color: #444564;
}
.cx-advanced-select__selectContainer__popup > input{
  font-size: 1rem;
  color: rgb(207, 207, 207);
  background-color: transparent;
  padding: .3rem .45rem;
  border: none;
  border-bottom: 1px solid currentColor;
  /* border-radius: 5px; */
  position: fixed;
  inset: 1rem 1rem auto 1rem;
  margin-bottom: 1rem;
}
.cx-advanced-select__selectContainer__popup > input::placeholder{
  opacity: .63;
  color: white
}
.cx-advanced-select__selectContainer__popup > input:focus{
  outline: none;
}

@keyframes slide-popup-select-advanced-in {
  to{
      opacity: 1;
      translate: 0 0;
  }
}






// utility
.flex{
  display: flex;
  gap: 1.4rem
}
.disabled{
  opacity: 0.3 !important;
  pointer-events: none !important;
}
.overflow-visible{
  overflow: visible;
}
.float-right{
  float: right;
}
.CXFormGorup{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: .25rem;
}
.cx-bg-success {
  background-color: var(--success-color) !important;
}
.cx-bg-danger{
  background-color: var(--danger-color) !important;
}

.cx-status-active{
  background-color: #0ca80c;
}
.cx-status-pending{
  background-color: #ffd70e;
}
.cx-status-notActive{
  background-color: #af1f09;
}
.transform-down-3{
  transform: translateY(7px);
}
.display-flex{
  display: flex;
}








.permissionsTable{
  
}

.permissionsTable__permissionRow{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr; 
  grid-gap: .5rem;
  align-items: flex-start;
  padding: 1rem 0;
}

.permissionsTable__permissionRow:not(:last-of-type):not(:first-of-type){
  border-bottom: 1px solid rgb(181, 181, 181);
}

.permissionsTable__permissionRow__organization{
  color: white;
}

.permissionsTable__permissionRow__roles{
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  width: 100%;
}
.PermissionRow__actions{

}
.permissionsRow__editBtn{
  color: var(--success-color);
}
.permissionsRow__deleteBtn{
  color: var(--danger-color);
}






//cx-permissions-array

.cx-permissions-list{
  margin-top: 1.5rem;
}

.cx-permissions-row{
  display: flex;
  gap: 1rem;
  padding: .2rem .85rem;
  border-left: 2px solid var(--light-gray);
  align-items: center;
  margin-bottom: 1.4rem;
}



// Add Permission Form
.addPermissionArea{
  margin-bottom: 7rem;
  padding-top: 2rem;
  border-top: 1px solid var(--gray);
}
.addPermissionArea > h4{
  color: #4090b4
}

.addPermissionForm, .editPermissionForm{
  display: flex;
  align-items: end;
  justify-content: start;
  gap: 2rem;
}

.addPermissionForm__actions{
  margin-left: auto;
}
.addPermissionForm__actions button,
.editPermissionForm__actions button{
  background-color: transparent !important;
}
.addPermissionForm__actions button{
  padding: .5rem 1rem !important;
}
.addPermissionForm__actions button:not(:last-of-type),
.editPermissionForm__actions button:not(:last-of-type){
  margin-right: .5rem !important;
}
.addPermissionForm__submitBtn{
  color: #4090b4 important;
  border: 2px solid #4090b4 !important;
}
.addPermissionForm__cancleBtn{
  color: #fd5d93 !important;
  border: 2px solid #fd5d93 !important;
}
.addPermissionForm__submitBtn:hover{
  background-color: #4090b4 !important;
  color: white !important;
}
.addPermissionForm__cancleBtn:hover{
  background-color: #fd5d93 !important;
  color: white !important;
}






//segment page
:root{
  --segment-card-top-padding: 1rem;
  --segment-card-right-padding: 1.5rem;
}

.segmentationList{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 4.5rem;
}

.segment-card-container{
  padding: 1rem 1.5rem .2rem 1.5rem;
  width: 350px;
  cursor: pointer;
  background-color: #32334d;
  color: white;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 #00000040;
  position: relative;
  transition: .3s;
  &:hover{
    box-shadow: 0 0 0 1px #21c8df, 0 0 5px 1px #21c8df;
  }
  &:hover &__action{
    opacity: 0.7;
  }

  &__title{
    font: 1.8rem "poppins";
    padding-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: .2rem;
  }

  &__topRight{
    position: absolute;
    top: 1.9rem;
    right: var(--segment-card-right-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    
    &__status{
      transition: .3s;
      width: 15px;
      height: 15px;
      background-color: #0ca80c;
      border-radius: 50%;
    }
    &__delete-btn{
      transition: .3s;
      color: var(--danger-color);
      opacity: 0;
    }
  }
  &:hover &__topRight__delete-btn{
    opacity: 1;
  }
  
  &__dimensions{
    color: #adadb8;
  }
  
  &__users{
    color: #adadb8;
    text-align: right;
    font-size: 1.3rem;
  }
  
  &__users span{
    color: #21c8df;
    font-size: 3rem;
    font-weight: 900;
    line-height: 80px;
  }

  &__action{
    position: absolute;
    left: var(--segment-card-right-padding);
    bottom : 1.35rem;
    color: #21c8df;
    opacity: 0;
    transition: .3s;
  }
}

.add-segment-btn{
  display: grid;
  place-items: center;
  width: 165px;
  height: 165px;
}
.add-segment-btn i{
  font-size: 5rem;
  opacity: 0.6;
  transition: .3s;
}
.add-segment-btn:hover i{
  opacity: 1;
}
.segmentation-dimension-pill-container{
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}
.segmentation-dimension-pill{
  border-radius: 7px;
  background: #14141F;
  box-shadow: 0 2px 2px 0 #0004;
  padding: .5rem 1.2rem;
}
.segmentation-dimension-pill-dimension{
  background-color: #e8e5ff;
  color: black;
}


.segment-form{
  &__title{
    margin-bottom: 2rem;
    color: #ccc;
    font-size: 1rem;
  }

  & label{
    color: #7b808e;
    font-size: 1.1rem;
  }
  &__segment-name{
    padding: 10px 0;
  }
  &__segment-name input{
    background-color: transparent;
    font-size: 1.3rem;
    color: white;
    padding: .6rem;
    width: 90%;
    border: none;
    border-radius: 5px;

    &:hover, &:focus{
      outline: 1px solid #21c8df;
    }
    
  }

  .user-meter-wrapper{
    width: 500px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    label{
      margin: 0;
    }

    .user-meter-bar{
      position: relative;
      flex-grow: 1;
      height: 20px;
      border-radius: 4px;
      background-color: #216981;
  
      &__progress{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        background: linear-gradient(to top right,#21c8df, #25e9a6) !important;
      }
      &__percentage{
        position: absolute;
        top: 50%;
        right: 10px;
        color: black;
        transform: translateY(-50%);
        font-weight: 900;
        z-index: 3;
      }
    }
  }



  .segment-save-btn{
    background: linear-gradient(to top right,#21c8df 40%, #25e9a6) !important;
    padding: .5rem 1.2rem !important;
    font-weight: 900;
    letter-spacing: 2px;
  }

  &__dimensions-list{

  }
  &__dimensions-item{
    width: 100%;
    display: grid;
    grid-template-columns: auto auto 2fr auto; 
    grid-gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    &:not(:last-of-type){
      border-bottom: 1px solid rgb(181, 181, 181);
    }

    .segment-item-input{
      height: 100%;
    }

  }


}

.segment-form.segment-create-mode .segment-form__segment-name > input{
  border-bottom: 1px solid #484a71;
}




// content grouping
//card
.contentG-card-container{
  padding: 1rem 1.5rem .2rem 1.5rem;
  width: 350px;
  cursor: pointer;
  background-color: #32334d;
  color: white;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 #00000040;
  position: relative;
  transition: .3s;
  &:hover{
    box-shadow: 0 0 0 1px #21c8df, 0 0 5px 1px #21c8df;
  }
  &:hover &__action{
    opacity: 0.7;
  }

  &__title{
    font: 1.8rem "poppins";
    padding-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: .2rem;
  }

  &__topRight{
    position: absolute;
    top: 1.9rem;
    right: var(--segment-card-right-padding);
    width: 15px;
    height: 15px;

    &__delete-btn{
      position: absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%, -50%);
      transition: .3s;
      color: var(--danger-color);
      opacity: 0;
    }
  }
  &:hover &__topRight__delete-btn{
    opacity: 1;
  }

  &__nbOfKeywords{
    color: #adadb8;
  }

  &__coverage{
    color: #adadb8;
    text-align: right;
  }
  
  &__coverage span{
    color: #21c8df;
    font-size: 3rem;
    font-weight: 900;
    line-height: 80px;
  }

  &__action{
    position: absolute;
    left: var(--segment-card-right-padding);
    bottom : 1.35rem;
    color: #21c8df;
    opacity: 0;
    transition: .3s;
  }
}

//form
.contentG-submitBtn{ 
  border: none;
  background: linear-gradient(to bottom right, #25e9a6, #21c8df);
  font-weight: 900;
  border-radius: 5px;
  padding: 0 2rem;
  cursor: pointer;
  outline: none;
}
.selected-keywords-title, .pool-title{
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.selected-keywords-container{
  padding: 1rem 1.5rem;
  background-color: #222A42;
  box-shadow: inset 0px -6px 10px 1px #2D3752, inset 0px 0px 8px 5px rgba(22, 28, 44, 0.99);
  border-radius: 10px;
  resize: vertical;
  overflow: auto;
  position: relative;
  height: 14vh;
  flex-grow: 1;
}

.pool-container{
  display: grid;
  grid-template-columns: 2fr 5fr;
  border-radius: 10px;

  .pool-filters{
    height: 100%;
    background-color: #dce2ef;
    padding: 1rem 1.2rem;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;

    .filters-title{
      display: flex;
      gap: 10px;
      width: fit-content;
      color: #14141F77;
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 1.23rem;
    }

    .filters-body{
      
    }

    .filters-action-btns{
      display: flex;
      gap: .8rem;
      flex-grow: 1;
      align-items: end;

      button{
        box-sizing: border-box;
        flex-grow: 1;
        padding: .4rem;
        border: 1px solid #4b4c6e;
        background-color: transparent;
        border-radius: 5px;
        color: #2f3046;
        height: fit-content;
        outline: none;
        transition: .3s;
        cursor: pointer;

        &:hover{
          transform: translateY(-3px);
          box-shadow: 0 3px 5px #2222 ;
        }
      }

      button:nth-of-type(2){
        background: linear-gradient(to top right, #25e9a6, #21c8df);
        font-weight: 900;
        border: none;
      }
    }

    .es-filter-group{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      margin-bottom: 1rem;

      label{
        font-size: 1rem;
        color: #222A42;
        font-weight: 700;
        margin: 0;
        margin-bottom: .2rem;
      }

      .contentG-filter-es{
        width: 100%;
      }
      .contentG-filter-es-text input{
        background-color: #4b4c6e;
        padding: .4rem .8rem;
        &::placeholder{
          color: #fffA
        }
      }
    }

    

  }


  .pool-results-container{
    background-color: #464767;
    border-radius: 0 10px 10px 0;
    padding: 1rem 1rem 0 1rem;
    min-height: 58vh;
    max-height: 58vh;
    overflow: auto;
    display: flex;
    gap: 10px;

    &__pills-pool{
      display: flex;
      gap: 7px;
      flex-wrap: wrap;
      height: fit-content;

      .cx-pill{
        cursor: pointer;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
      }
    }
  
  }

}



























.publisher-form-configuration{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  align-items: start;
}







.cx-input-icon-input-error{
  border: 1px solid #fd5d93 !important;
  box-shadow: 0 0 10px -3px #fd5d93;
  border-radius: 0 !important;
}
span.errorMsg{
  margin-top: .2rem;
  color: #fd5d93 !important;
  font-weight: 100;
}











//fixes
.campaign-form-check-sign::before, .campaign-form-check-sign::after{
  width: 100% !important;
  height: 100% !important;
  font-size: .85rem !important;
}
.campaign-form-check-sign::after{
  top: 1px !important;
}
.number-align-right{
  margin-left: auto;
}


//post card used in article search
.styleCard {
  border-radius: 8px;
  box-shadow: 0px 0px 8px #ccc;
  color: #253b56;
  margin: 12px 0px;
}

.styleCardContent {
  padding: 4px 16px 20px 16px;
}

.styleImage {
  background-color: lightGray;
  border-radius: 8px 8px 0 0;
  max-height: 140px;
  overflow: hidden;
}

.styleCardTitle {
  font-family: Avenir-Heavy;
  font-size: 20;
  font-weight: 600;
  margin: 12px 0px 0px 0px;
}

.styleLocationLabel {
  color: #8294aa;
  font-size: 14;
  margin: 4px 0;
}

.styleDescription {
  font-size: 14;
  margin: 8px 0 0 0;
}

.getDetailsBtn{
  all: unset;
  background-color: #4090B4;
  border-radius: 0 8px 8px 0;
  color: white;
  min-width: 120px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 2px;
  right: 0;
}

.getDetailsBtn:focus{
  outline: none;
}

.boostedPostList{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}


.postUrlInputField input{
  padding-right: 121px !important;
}


.btn-blue-gradient{
  background: linear-gradient(120deg, #085cd2 30%, #09e6be) !important;
  background-size: 150% 150% !important;
  background-position: 0 0 !important;
  transition: 0.4s !important;
}
.btn-blue-gradient:hover{
  background-position: 100% 100% !important;
}


.weekdays-chips-radio{
  .cx-radio-group__options{
    gap: 5px;
  }
  .cx-radio-group__options__item{
    label{
      border-radius: 5px !important;

    }

  }
}


.image-upload-newsletter-container{

  position: relative;
  border-radius: 3px;
  border: 2px dashed #fff2;
  background-color: #ffffff0d;
  padding: 25px 0 50px 0;

  .image-upload-newsletter{


    button {
      position: absolute;
      bottom : 7px;
      right: 7px;
    }

    i {
      font-size: 8rem;
    }

  }


}


.cx-advanced-select__active-item{
  font-weight: bold;
}


.addEmailBtn{
  background: linear-gradient(45deg, #085cd2, #09e6be) !important;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 !important;
}

.opacity-5{
  opacity: 5;
}

.coreui-cx-card{
  border: 1px solid #fff2 !important;

}

.emailDigestInputContainer{
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  input {
    padding-right: 30px
  }

  button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 !important;
  }
  
  button:hover {
    transform: translateY(-50%) !important;
  }
}

.cx-card-status{
  width: fit-content;
  padding: 4px 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
}

.cx-card-status-active{
  border: 1px solid #0ca80c;
  color: #0ca80c;
  background-color: #0ca80c33;
}

.cx-card-status-not-active{
  border: 1px solid #fd5d93;
  color: #fd5d93;
  background-color: #fd5d9322;
}


.cursor-pointer{
  cursor: pointer;
}


.newsletter-card-container {
  --inline-padding: 1.5rem;
  width: 320px;
  height: 100%;
  background-color: #32334d;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 #00000040;
  position: relative;
  padding: 1rem var(--inline-padding);
  cursor: pointer;

  &:hover {
      box-shadow: 0 0 0 1px #21c8df, 0 0 5px 1px #21c8df;
  }

  & * {
      margin: 0;
  }

  & .title {
      font-size: 1.8rem;;
      padding-right: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 0.2rem;
  }

  & .status {
      position: absolute;
      top: 30px;
      right: var(--inline-padding);
      width: 15px;
      height: 15px;
      border-radius: 100%;
  }

  & .status-active {
      background-color: green;
  }
  & .status-not-active {
      background-color: red;
  }

  & .typeTag{
    position: absolute;
    bottom: 16px;
    left: var(--inline-padding);
    font-weight: 600;
    font-size: 15px;
    color: #25ABC2;
    padding: 2px 10px;
    background: rgba(33, 200, 223, 0.21);
    border-radius: 4px;
  }
  
  & .metric{
    font-size: 4rem;
    position: absolute;
    bottom : -10px;
    right: var(--inline-padding);
    color: #25ABC2;
    font-weight: 900;

    span{
      display: inline-block;
      font-size: 20px;
      color: white;
      margin-left: 3px;
      opacity: 0.2;
      font-weight: 600;
      transform: translateY(-3px);
    }
  }

}

.add-newsletter-btn {
  display: grid;
  place-items: center;
  width: 165px;
  height: 165px;
  cursor: pointer;
  background-color: #32334d;
  color: white;
}
.add-newsletter-btn i {
  font-size: 5rem;
  opacity: 0.6;
  transition: 0.3s;
}
.add-newsletter-btn:hover i {
  opacity: 1;
}



.delete-newsletter-btn{
  background-color: #af1f09 !important;
  margin-left: auto !important;
}

.delete-article-boost{
  background-color: #af1f09 !important;
  padding: 4px 10px !important;
}







// survey start


.survey-form {

  .survey-types-tab-wrapper{

    h2{
      margin: 0;
    }

    .survey-types-cards{
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
    }
  
    .survey-type-card {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-direction: column;
      border-radius: 8px;
      background-color: #4b4c6e;
      padding: 20px 25px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: .3s;
  
      img {
        width: 80px;
        height: 80px;
      }
  
      h2 {
        margin: 0;
        font-size: 18px;
        transition: .3s;
      }
      &:hover{
        border-color: rgb(51, 216, 228);
      }
      &:hover h2 {
        color: rgb(51, 216, 228);
      }

      &.selected {
        background-color: rgba(51, 216, 228, .3);
        
        border-color: rgb(51, 216, 228);
        font-weight: bold;

        h2{
          color: rgb(51, 216, 228);
        }
      }


    }
  }


  .choices{
    display: flex;
    flex-direction: column;
    gap: 12px;

    .choice{
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      .close-btn{
        cursor: pointer;

        &:hover{
          color: rgb(51, 216, 228)
        }
      }
    }
  }
}


.targeting-form{

  section {
    display: grid;
    grid-template-columns: 1fr 3.5fr;


    .info{
      padding: 22px 30px;
      padding-left: 0;
      text-align: right;
      

      .title{
        font-weight: bold;
        font-size: 16px;
      }

      .desc{
        opacity: .45;
        font-size: 14px;
      }
    }

    .fields {
      padding: 22px 30px;
      border-left: 1px solid #fff3;
      display: flex;
      flex-direction: column;
      gap: 16px;


      .targeting-field-column{
        flex-direction: column;
      }

      .cx-popup-form-layout{
        flex-direction: column;
      }
      .cx-input-label{
        width: 100%;
      }
    }



  }



}



.custom-highcharts-patch .highcharts-container{
  width: 1000px !important;
}


.email-newsletter-analysis-cards{
  display: flex;
  gap: 10px;

  & > div {
    margin : 0;
    margin-left: 38px;
  }
}

.newsletter-card-container{
  height: 165px !important;
}

.cx-mt-2{
  margin-top: 2rem;
}




